import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/user.service";
import { setMessage } from "./message";
import { toast } from 'react-toastify';

export const getCources = createAsyncThunk(
  "activity/get",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getPublicContent(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const contactus = createAsyncThunk(
  "activity/contactus",
  async (body,thunkAPI) => {
    try {
      const response = await userService.contactus(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addActivity = createAsyncThunk(
  "activity/add",
  async (body,thunkAPI) => {
    try {
      const response = await userService.addActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const editActivity = createAsyncThunk(
  "activity/edit",
  async (body,thunkAPI) => {
    try {
      const response = await userService.editActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const removeActivity = createAsyncThunk(
  "activity/delete",
  async (body,thunkAPI) => {
    try {
      const response = await userService.removeActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const singleActivity = createAsyncThunk(
  "activity/single",
  async (body,thunkAPI) => {
    try {
      const response = await userService.singleActivity(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserList = createAsyncThunk(
  "activity/user",
  async (body,thunkAPI) => {
    try {
      const response = await userService.getUserList(body);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = {}
const courseSlice = createSlice({
  name: "course",
  initialState,
  extraReducers: {
    [getCources.fulfilled]: (state, action) => {
      state.allcourse = action.payload.data;
    },
    [getCources.rejected]: (state, action) => {
      state.allcourse = null;
    },
  },
});


const { reducer } = courseSlice;
export default reducer;
