import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import Footer from './Footer';
import {  singleActivity } from '../store/api/course.api';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Contact from "./Contact";

import moment from 'moment'

const EventDetails = () => {
    const [show, setShow] = useState(false);
    const [activity, setActivity] = useState();
    const dispatch = useDispatch();
    const { slug } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        let data = {
            slug:slug
        }
        dispatch(singleActivity(data)).then((act) => {
            setActivity(act.payload?.data);
        });
    }, []);
    const goback = () => {
        navigate('/');
    }
    const callbackModal = () => {
        
     }
    return (
        <div className="bg-black page-dark">
            <header className="header px-0">
                <Container>
                    <Button className="d-inline-block" variant="link" onClick={() => goback()} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                            <path d="M1.22498 5.45002L0.799234 5.02427L0.373492 5.45002L0.799234 5.87576L1.22498 5.45002ZM15.6751 6.05211C16.0076 6.05211 16.2772 5.78254 16.2772 5.45002C16.2772 5.11749 16.0076 4.84793 15.6751 4.84793V6.05211ZM5.61595 0.207559L0.799234 5.02427L1.65072 5.87576L6.46743 1.05904L5.61595 0.207559ZM0.799234 5.87576L5.61595 10.6925L6.46743 9.84099L1.65072 5.02427L0.799234 5.87576ZM1.22498 6.05211H15.6751V4.84793H1.22498V6.05211Z" fill="white" />
                        </svg>
                    </Button>
                </Container>
            </header>
            <section className=" pt-md-3 pb-120 mobile-pt-20">
                <Container >
                    <div className="rounded-3 overflow-hidden mb-md-4 mobile-mb-20  mobile-relative">
                        <Row className="gx-0  gy-4">
                            <Col md={6} className="position-relative">
                                <div className="position-relative h-100 rounded-2 overflow-hidden">
                                    <Image src={activity?.image} alt="Daniel Ek" className="mobile-relative position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100 object-fit" />

                                </div>
                            </Col>
                            <Col md={6} className="mobile-relative">
                                <div className="text-white p-md-4 d-flex flex-column h-100 ">
                                    <span className="d-block font-32 font-secondary mb-2">{activity?.title}</span>
                                    <h6 className="fw-bold font-22 d-block mb-2">{activity?.sub_title}</h6>
                                    <div className="block font-16 fw-light mb-auto" >
                                        <p dangerouslySetInnerHTML={{ __html: activity?.description }}></p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4 justify-content-between">
                                        <div className="w-100">
                                            <span className="font-16 mb-2 fw-light d-block">Datum: {moment(activity?.activity_date).format("DD MMM YYYY")} <br />Tid: {moment(activity?.activity_date).format("HH:mm")} - {moment(activity?.activity_end_date).format("HH:mm")}</span>
                                            <Contact title={''} btn_title={'Anmäl dig här!'} activity_id={activity?.id} callbackModal={callbackModal} isShowTitle={false} isBig={false} isCurrent={false} />
                                        </div>
                                    </div>
                                    <div className="text_center">
                    <p className="terms_style_mobile">När du anmäler dig godkänner du att din mail används till mailutskick/aktiviteter från arrangörerna. Inga mailadresser lämnas vidare till en tredje part.</p>
                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default EventDetails;