import React, { useState, useEffect } from 'react';
import { Container, Button, Form, InputGroup, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from './Footer';
import Header from './Header';
import {register} from '../store/api/auth.api';

const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [value, setValue] = useState({ email: "", password: "",register_type:"private",firstname: "", lastname: "" });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [passwordType, setPasswordType] = useState("password");


    const onAdd = () => {
         setLoading(true);
         dispatch(register(value))
         .unwrap()
         .then(() => {

          navigate('/admin/list');
         })
         .catch(() => {
            setLoading(false); 
         });
       
    };
    const validate = (event, name, value) => {
        //A function to validate each input values
        switch (name) {
            case 'email':
                if (
                    !new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        email: "Enter a valid email address"
                    })
                } else {

                    let newObj = omit(errors, "email");
                    setErrors(newObj);

                }
                break;

            case 'password':
                if (
                    !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
                ) {
                    setErrors({
                        ...errors,
                        password: "Password should"
                    })
                } else {
                    let newObj = omit(errors, "password");
                    setErrors(newObj);
                }
                break;

            default:
                break;
        }
    }
    const onChange = (e) => {
        validate(e, e.target.name, e.target.value);

        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (
        <>
            <div>
                <Header/>
                <div className='text-center pt-2'>
                    <Container fluid>
                        <Form className="form-block mx-auto max-387 border  text-center  border-secondary loginpage px-11 pb-11 pt-14 rounded-3 wow fadeInDown" >
                            <div className="text-center ">
                                <h1 className='font-45 d-block mt-1 mb-4'>Signup</h1>
                            </div>
                            <Form.Group className="mb-4" controlId="formBasicEmail">
                                <Form.Control className='no_padding' type="email" name="email" placeholder="Email" autoComplete="off" aria-label="Email" value={value.email}
                                    onChange={onChange} />
                                {errors.email && <span>{errors.email}</span>}
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="formBasicPassword" >
                                <InputGroup className="password-input">
                                    <Form.Control
                                        type={passwordType}
                                        name="password"
                                        placeholder="Password"
                                        autoComplete="off"
                                        aria-label="Password"
                                        value={value.password}
                                        onChange={onChange}
                                        className='no_padding'
                                        required />
                                    <InputGroup.Text>
                                        <img className='pass_img' src={`${passwordType !== "password" ? "/assets/img/view.png" : "/assets/img/hide.png"}`} onClick={togglePassword}></img>
                                    </InputGroup.Text>
                                </InputGroup>
                                {errors.password && <span>{errors.password}</span>}
                            </Form.Group>
                            <Button variant="dark" type="button" className="form-button" onClick={onAdd}>
                                Signup
                            </Button>
                        </Form>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Signup;