import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./api/auth.api";
import messageReducer from "./api/message";
import courceReducer from "./api/course.api";
import cartReducer from "./api/cart"
const reducer = {
  auth: authReducer,
  message: messageReducer,
  course: courceReducer,
  cart: cartReducer
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
