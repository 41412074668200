import { Link } from "react-router-dom";
import {  Container, Image } from "react-bootstrap";


const Footer = () => {

    return (
        <footer className="">
                <Container>
                <div className=" text-center">
                    <span>Ett initiativ av:</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="footer-link">
                            <Link to="/"><Image src="/assets/img/Booky-logo.svg" alt="Bookey" /></Link>
                        </div>
                        <div className="footer-link">
                            <Link to="/"><Image src="/assets/img/Docens-logo.svg" alt="Docen" /></Link>
                        </div>
                    </div>
                </Container>
            </footer>
    )
}

export default Footer;