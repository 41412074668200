import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import ActivityList from "../Pages/ActivityList";
import UserList from '../Pages/UserList'
import Signup from "../Pages/Signup";
import Detail from "../Pages/Detail";
import EventDetails from '../Pages/EventDetails';
const Routing = () => {
    return(
        <Routes >
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/admin-signup" element={<Signup />} />
            <Route path="/admin/list" element={<ActivityList />} />
            <Route path="/admin/user_list/:id" element={<UserList />} />
            <Route path="/details/:slug" element={<Detail />} />
            <Route path="/föreläsning/:slug" element={<Detail />} />
            <Route path="/event/:slug" element={<EventDetails />} />
            <Route path="/event-details/:slug" element={<EventDetails />} />
        </Routes>
    )
}

export default Routing;