import {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {logout} from '../store/api/auth.api';
import { useNavigate } from 'react-router-dom';
import {Button} from 'react-bootstrap';


const Header = () => {
    const [open, setOpen] = useState();
    const pageName = window.location.pathname;
    const scrollToSection = (sectionId) => {
      if(pageName === '/') {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
        setOpen(false);
      } else {
        navigate('/');
        setOpen(false);
      }
      
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { role_id } = useSelector((state) => state.auth.user || {});
    const onLogout = () => {
        dispatch(logout());
        navigate('/');
     };

     const menuToggle = () => {
        setOpen(!open);
       
     }
   
    return (
        <div className="d-md-block d-flex justify-content-end header">
            <Button className="d-md-none d-inline-block" variant="link" onClick={menuToggle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                  <path d="M6.04173 8.45825H22.9584" stroke="#191919" stroke-width="0.763158" stroke-linecap="round"/>
                  <path d="M6.04173 14.5H22.9584" stroke="#191919" stroke-width="0.763158" stroke-linecap="round"/>
                  <path d="M6.04173 20.5417H22.9584" stroke="#191919" stroke-width="0.763158" stroke-linecap="round"/>
                </svg>
            </Button>

         <ul className={`d-md-flex align-items-center justify-content-center page-header list-unstyled py-md-4 py-2 m-0  top-0 z-1  navmenu-wrap ${open && 'open'}`}>
                  <li>
                    <a role="link" className="nav-link pointer link-hover" onClick={() => scrollToSection('section1')}>Kommande föreläsning</a>
                </li>
                <li>
                    <a role="link" className="nav-link pointer link-hover" onClick={() => scrollToSection('section2')}>Tidigare föreläsning</a>
                </li>
                <li>
                    <a role="link" className="nav-link pointer link-hover" onClick={() => scrollToSection('about')}>Om oss</a>
                </li>
                {(role_id === 3) &&
                <>
                 {pageName !== '/admin/list' && 
               <li>
                    <a  href='/admin/list' role="link" className="nav-link pointer link-hover" >Add new</a>
                </li>
                }
                <li>
                <a role="link" className="nav-link pointer link-hover" onClick={() => onLogout()}>Logout</a>
                 </li>
                </>
                
                }
                {/* <NavDropdown.Item onClick={onLogout}> {t('Logout')} </NavDropdown.Item> */}
            </ul>

        <div className="overlay position-fixed start-0 end-0 top-0 bottom-0 d-md-none" onClick={menuToggle}></div>
        </div>
       
    )
}

export default Header;