
import { useState, useEffect } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { contactus } from '../store/api/course.api';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { facebookSignupEvent } from "./facebookandgoogleevents";



const Contact = (prop) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({email: '' });
    const onChange = (e) => {
        setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
    const onAdd = () => {
      setLoading(true);
        let data = {
            email: value.email,
            activity_id: prop.activity_id,
            isCurrent: prop.isCurrent
          }
        
          dispatch(contactus(data)).unwrap().then((res) => {
          
            if(res.status === 200){
              toast.success(res.message);
              facebookSignupEvent(value.email)
            }
            if(!prop.isShowTitle){
              prop.callbackModal();

            }
            setValue({email: '' });
            setLoading(false);
          }).catch(err => {
            setLoading(false);
            toast.error(err.message)
          });
    };
    return (
                    <div className="text-center">
                      {prop.isShowTitle &&
                        <h3 className="font-secondary font-45 fw-normal text-black mb-2">{prop.title}</h3>
                        }
                        <Form  className={!prop.isShowTitle?'custum-place form-block mx-auto':'form-block mx-auto'} >
                            <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">

                                <Form.Control  className={` ${!prop.isShowTitle?'no-border ':''} ${prop.isBig?'long-text-box ':''} `} type="email" name="email" placeholder="E-post" value={value.email} onChange={onChange} readOnly={loading} />
                                <Button disabled={loading} variant={!prop.isShowTitle ?'light':'dark'} type="button" className="position-absolute form-button" onClick={onAdd}>
                                 <span > {prop.btn_title}</span> 
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
    )
}
export default Contact