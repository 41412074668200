import axios from "./httpService";
  const API_URL = axios.Api;

const addActivity = (body) => {
  return axios.post(API_URL + "activity/add",body);
};
const getPublicContent = (body) => {
  return axios.post(API_URL + "activity/list",body);
};
const contactus = (body) => {
  return axios.post(API_URL + "activity/contactus",body);
};
//activity/list
const removeActivity = (body) => {
  return axios.post(API_URL + "activity/delete",body);
};
const editActivity = (body) => {
  return axios.post(API_URL + "activity/edit",body);
};

const singleActivity = (body) => {
  return axios.post(API_URL + "activity/single",body);
};
const getUserList = (body) => {
  return axios.post(API_URL + "activity/user_list",body);
};


const userService = {
  getPublicContent,
  contactus,
  addActivity,
  removeActivity,
  editActivity,
  singleActivity,
  getUserList
};

export default userService