
import { useState, useEffect } from "react";
import {  Modal } from "react-bootstrap";
import moment from 'moment'
import Contact from "./Contact";


const MoreDetail = (prop) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    useEffect(() => {
        setShow(prop.show)
    }, []);
    const callbackModal = () => {
        handleClose();
     }
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton className="text-white d-block block">
                    <Modal.Title className="d-block font-45 font-secondary">{prop?.element?.title}</Modal.Title>
                    <p className="fw-bold font-22">{prop?.element?.sub_title}</p>
                </Modal.Header>
                <Modal.Body className="text-white fw-light block font-16 fw-light">
                    <p dangerouslySetInnerHTML={{ __html: prop?.element?.description }}></p>
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between">
                    <span className="font-16 text-white">Datum: {moment(prop?.element.activity_date).format("DD MMM YYYY")} <br />Tid: {moment(prop?.element.activity_date).format("HH:mm")} - {moment(prop?.element?.activity_end_date).format("HH:mm")} </span>
                    <Contact title={''} btn_title={'Anmäl dig här!'} activity_id={prop?.element?.id} callbackModal={callbackModal} isShowTitle={false} isBig={true} isCurrent={false} />
                </Modal.Footer>
                <div className="text_center">
                    <p className="terms_style">När du anmäler dig godkänner du att din mail används till mailutskick/aktiviteter från arrangörerna. Inga mailadresser lämnas vidare till en tredje part.</p>
                    </div>
            </Modal>
        </div>
    )
}

export default MoreDetail