import React, { useState, useEffect } from 'react';
import { Container, Image, Table, Button } from "react-bootstrap";
import { toast } from 'react-toastify';
import moment from 'moment'
import { getCources,removeActivity } from '../store/api/course.api';
import AddActivity from '../Pages/AddActivity'
import Header from './Header';
import Footer from './Footer';
import ConfirmPopup from './ConfirmPopup'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
const ActivityList = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [allCources, setAllCources] = useState();
    const [editId, setEditId] = useState(null);
    const { role_id } = useSelector((state) => state.auth.user || {});
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null
      });
    const openShow = (id) => {
        setEditId(id)
        setShow(true);
    }
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
        dispatch(getCources({})).then((course) => {
            setAllCources(course.payload?.data);
        });
    }
    const callbackModal = () => {
        setShow(false);
        refresh();
    }


    const handleDelete = (id) => {
        setPopup({
          show: true,
          id,
        });
      };
      const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
    
          let data = {
            'is_delete': 1,
            'slug': popup.id
          }
          dispatch(removeActivity(data))
            .unwrap()
            .then((res) => {
              if (res.status === 200) {
                refresh();
              }
            })
            .catch((e) => {
              console.log(e)
            });
    
          setPopup({
            show: false,
            id: null,
          });
        }
      };
      const handleDeleteFalse = () => {
        setPopup({
          show: false,
          id: null,
        });
      };
      const openUserDetai = (id) => {
        navigate('/admin/user_list/'+id);
      }
    return (
        <>
            <div>
                <Header />
                <Container>
                    {/* Starts Table */}
                    <div className='wow fadeInDown'>
                        <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
                            <span className="d-block  font-24 fw-light mb-2">Frukostföreläsningar</span>
                            {(role_id === 3) &&
                            <div className="right-button">
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => openShow(null)}>Lägg till</Button>
                                {show && editId === null &&
                                    <AddActivity show={show} callbackModal={callbackModal}></AddActivity>
                                }
                            </div>
                          }
                        </div>
                        <Table bordered hover responsive className='text-center'>
                            <thead>
                                <tr>
                                    <th>Nummer</th>
                                    <th>Rubrik</th>
                                    <th>Underrubrik</th>
                                    <th>Datum</th>
                                    <th>End Datum</th>
                                    <th>Count</th>
                                    {(role_id === 3) &&
                                    <th>Redigera</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allCources && allCources.length > 0 && allCources?.map((item, index) => (
                                        <tr key={item.id} >
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td onClick={()=>openUserDetai(item.id)}>{item.title}</td>
                                            <td onClick={()=>openUserDetai(item.id)}>{item.sub_title}</td>

                                            <td>{moment(item?.activity_date).format("DD-MMM-YYYY HH:mm")}</td>
                                            <td>{moment(item?.activity_end_date).format("DD-MMM-YYYY HH:mm")}</td>
                                            <td>{item.contact[0].count}</td>
                                            {(role_id === 3) &&
                                            <td>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                <a className=' btn-link icon-ic-edit text-white  action-btn' onClick={() => openShow(item.slug)}> <img className='pass_img' src={`/assets/img/edit.png`} ></img></a>
                                                  {(show && item.slug !== null && editId === item.slug) &&
                                                    <AddActivity show={show} editId={item?.slug}  callbackModal={callbackModal} />
                                                  }
                                                <a onClick={() => handleDelete(item.slug)} className=' btn-link icon-ic-trash text-white  action-btn'>
                                                <img className='pass_img' src={`/assets/img/delete.png`} ></img>
                                                </a>
                                                {popup.show && (
                                                        <ConfirmPopup
                                                        handleDeleteTrue={handleDeleteTrue}
                                                        handleDeleteFalse={handleDeleteFalse}
                                                        message={('You sure you wanna delete')}
                                                        />
                                                        )}
                                                </div>
                                            </td>
                                            }
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                        {allCources && allCources.length === 0 &&
                            <p className='text-center'>Tomt</p>
                        }
                    </div>
                    {/* Ends Table */}
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default ActivityList;