
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Image, Modal, Row } from "react-bootstrap";
import Slider from "react-slick";
import { useDispatch } from 'react-redux';
import { getCources } from '../store/api/course.api';
import moment from 'moment'
import MoreDetail from "./MoreDetail";
import Contact from "./Contact";
import Footer from "./Footer";
import Header from "./Header";
import {isMobile} from 'react-device-detect';
import { useNavigate, Link } from 'react-router-dom';
import AboutUs from "./AboutUS";

const Home = () => {
    const dispatch = useDispatch();
    const [allCources, setAllCources] = useState();
    const [pastCources, setPastCources] = useState();
    const navigate = useNavigate();
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: true,
                     infinite: true,
                     slidesToShow:1,
                     slidesToScroll: 1,
                     centerMode: true,
                     centerPadding: '27%',
                },
            }
        ],
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [deadline, setDeadline] = useState();
    const [displayTimer, setDisplayTimer] = useState(true);
    //const deadline = "December, 31, 2023";
    const [editId, setEditId] = useState(null);
    const [limit,setLimit] = useState(4);
    const getTime = (date) => {
        const time = Date.parse(date) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
        if (time <= 0) {
            setDisplayTimer(false);
        } else {
            setDisplayTimer(true)
        }
    };

    let futureData = {
        is_future: true
    }
    let pastData = {
        is_future: false
    }
    useEffect(() => {
        dispatch(getCources(futureData)).then((course) => {
            setAllCources(course.payload?.data);
            // console.log(course.payload.data[0].activity_date)
            if (course.payload?.data[0]?.activity_date) {
                setDeadline(course.payload.data[0]?.activity_date);
              //  setDeadline('2023-09-02T02:12:00.000Z');

            }
        });
    }, [deadline]);

    useEffect(() => {
        dispatch(getCources(pastData)).then((course) => {
            setPastCources(course.payload?.data);
        });
    }, []);

    useEffect(() => {
        if (deadline) {
            const interval = setInterval(() => getTime(deadline), 1000);
            return () => clearInterval(interval);
        }
    }, [deadline]);
    const callbackModal = () => {
        setShow(false);

    }
    const handleShow = (slug) => {
        if(isMobile) {
            navigate('/event/'+slug);
        } else {
            setEditId(slug)
            setShow(true);
        }
        
    }
   
     const showVideo = (url) => {
        window.open(url,'_blank')
     }
     const add3Dots = (string, limit) => {
        if(string) {
           var dots = "...";
           if (string?.length > limit) {
              string = string.substring(0, limit) + dots;
           }
        }
        return string !="null" ? string : '';
     }
    return (
        <div>
           <Header/>

            {displayTimer && deadline &&
                <section className="py-md-5 pt-3 mobile-pb-80" >
                    <Container>
                        <div className="pb-md-5">
                            <span className="d-block text-center font-24 fw-light mb-4">NÄSTA FRUKOSTFÖRELÄSNING</span>
                            <Row className="gy-4">
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{days}</span>
                                        <span className="font-45 d-block mt-1">Dagar</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{hours}</span>
                                        <span className="font-45 d-block mt-1">Timmar</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{minutes}</span>
                                        <span className="font-45 d-block mt-1">Minuter</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{seconds}</span>
                                        <span className="font-45 d-block mt-1">Sekunder</span>
                                    </div>
                                </Col>
                            </Row>
                            <div className="block text-black font-16 mt-md-3 mt-4 text-center mobile-font-light">
                                <p>Vi älskar inspiration, kunskap & den första koppen kaffe på morgonen!
                            Varje onsdag bjuder vi på en boost av inspiration & kunskap från Sveriges främsta föreläsare. Allt du behöver göra är att signa upp dig, klicka in på det digitala eventet & hämta en kaffe – vi sköter resten! <br></br><br></br>

                            Inspiration är en färskvara - detta initiativ skapades därför för att bjuda dig på löpande inspiration, kunskap & nya infallsvinklar. Men även för att ge dig ett smakprov på nya föreläsare som du inte haft möjligheten att lyssna på! <br></br><br></br>


                            Detta är för dig som är nyfiken, vill lära nytt, inspireras eller hitta rätt föreläsare/talare till er konferens!<br></br>

                          <span className="fw-semibold">  Let's get inspired - it's a superpower!</span>
                            </p>
                            </div>
                        </div>
                       
                    </Container>
                </section>
            }
            {/* display on same date */}
            {!displayTimer && deadline &&
                <section className=" py-md-3 pb-3 mobile-pt-20" >
                    <Container>
                        <div className=" pb-3">
                            <span className="d-block text-center font-45 fw-light mb-4">Sänder just nu!</span>
                            <div className="rounded-3 overflow-hidden mb-md-4 mobile-mb-20 bg-black rounded-4 overflow-hidden mobile-relative">
                                <Row className="gx-0 min-height">
                                    <Col md={6} className="bg-gredient position-relative">
                                        <div className="position-relative h-100">
                                            <Image src={allCources[0]?.image} alt="Daniel Ek" className="mobile-relative position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100 object-fit" />

                                        </div>
                                        <svg style={ {'margin-left': 'auto'}} className="blink d-md-none d-block" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                <circle cx="15" cy="15" r="15" fill="#FF0000"/>
                                                </svg>
                                    </Col>
                                    <Col md={6} className="mobile-relative">
                                        <div className="bg-black text-white p-md-4 mobile-p-10 d-flex flex-column h-100 mobile-card">
                                        <div className="d-flex  ">
                                            <span className="d-block font-32 font-secondary mb-1">{allCources[0]?.title}</span>
                                            <svg style={ {'margin-left': 'auto'}} className="blink d-md-block d-none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                                <circle cx="15" cy="15" r="15" fill="#FF0000"/>
                                                </svg>
                                            </div>
                                            <h6 className="fw-bold font-22 d-block mb-2">{allCources[0]?.sub_title}</h6>
                                            <div className="block font-16 fw-light mb-auto" dangerouslySetInnerHTML={{ __html: add3Dots(allCources[0]?.description,460) }}>
                                            </div>
                                            <div className="d-flex align-items-center mt-10 justify-content-between">
                                            <Contact title={''} callbackModal={callbackModal} btn_title={'Anslut'} activity_id={allCources[0]?.id} isShowTitle={false} isCurrent={true} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </section>
            }
            <section className="pt-md-3 pt-2 pb-md-5 mobile-pb-80" id="section1">
                <Container>
                    <div className="text-center mb-md-4 mobile-mb-20">
                        <span className="fw-light d-block font-32 mb-1">Kommande föreläsningar</span>
                        <span><Image src="/assets/img/arrow-down.svg" alt="Nästa event" /></span>
                    </div>
                    <div className="block">

                        {allCources && allCources?.slice(0, limit)?.map((element, index) => {
                            if (!displayTimer && index != 0) {
                                return (
                                    <div className="rounded-3 overflow-hidden mb-md-4 mobile-mb-20 bg-black rounded-4 overflow-hidden mobile-relative" key={index}>
                                    <Row className="gx-0 min-height">
                                        <Col md={6} className="bg-gredient position-relative">
                                            <div className="position-relative h-100">
                                                <Image src={element?.image} alt="Daniel Ek" className="mobile-relative position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100 object-fit" />
        
                                            </div>
                                        </Col>
                                        <Col md={6} className="mobile-relative">
                                            <div className="bg-black text-white p-md-4 mobile-p-10 d-flex justify-content-between flex-column h-100 mobile-card">
                                                <span className="d-block font-32 font-secondary mb-1">{element?.title}</span>
                                                <h6 className="fw-bold font-22 d-block mb-2">{element?.sub_title}</h6>
                                                <div className="block font-16 fw-light" dangerouslySetInnerHTML={{ __html: add3Dots(element?.description,460) }}>
                                                </div>
                                                <div className="d-flex align-items-center mt-10 justify-content-between">
        
                                                    <span className="font-16">Datum: {moment(element?.activity_date).format("DD MMM YYYY")} <br />Tid: {moment(element?.activity_date).format("HH:mm")} - {moment(element?.activity_end_date).format("HH:mm")}</span>
                                                    <Button variant="light" onClick={()=> handleShow(element?.slug)}>Anmäl dig här!</Button>
                                                    {show && editId === element?.slug &&              
                                                     <MoreDetail show={show} callbackModal={callbackModal} element={element} ></MoreDetail>
                                                     }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                )
                            } else {
                                return (
                                    <div className="rounded-3 overflow-hidden mb-md-4 mobile-mb-20 bg-black rounded-4 overflow-hidden mobile-relative" key={index}>
                                    <Row className="gx-0 min-height">
                                        <Col md={6} className="bg-gredient position-relative">
                                            <div className="position-relative h-100">
                                                <Image src={element?.image} alt="Daniel Ek" className="mobile-relative position-absolute start-0 end-0 top-0 bottom-0 w-100 h-100 object-fit" />
        
                                            </div>
                                        </Col>
                                        <Col md={6} className="mobile-relative">
                                            <div className="bg-black text-white p-md-4 mobile-p-10 d-flex flex-column justify-content-between h-100 mobile-card">
                                                <span className="d-block font-32 font-secondary mb-1">{element?.title}</span>
                                                <h6 className="fw-bold font-22 d-block mb-2">{element?.sub_title}</h6>
                                                <div className="block font-16 fw-light" dangerouslySetInnerHTML={{ __html: add3Dots(element?.description,460) }}>
                                                </div>
                                                <div className="d-flex align-items-center mt-10 justify-content-between">
        
                                                    <span className="font-16">Datum: {moment(element?.activity_date).format("DD MMM YYYY")} <br />Tid: {moment(element?.activity_date).format("HH:mm")} - {moment(element?.activity_end_date).format("HH:mm")}</span>
                                                    <Button variant="light" onClick={()=> handleShow(element?.slug)}>Anmäl dig här!</Button>
                                                    {show && editId === element?.slug &&              
                                                     <MoreDetail show={show} callbackModal={callbackModal} element={element} ></MoreDetail>
                                                     }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                )
                            }
                        })}

                        <div className="text-center pt-md-1">
                            {limit === 4 &&
                            <Link to="/" onClick={()=> setLimit(100)} className="btn btn-link text-black font-22 text-decoration-none p-0 mobile-font-16" >Visa fler</Link>
                            }
                            {limit !== 4 &&
                            <Link to="/" onClick={()=> setLimit(4)} className="btn btn-link text-black font-22 text-decoration-none p-0" >Visa mindre</Link>
                            }
                            </div>

                    </div>

                </Container>
            </section>
            

            <section className="pt-md-4  pb-120 overflow-hidden" id="section2">
                <Container>
                    <div className="text-center mb-md-4 mobile-mb-20">
                        <span className="fw-light d-block font-32 mb-1">Tidigare föreläsningar</span>
                        <span><Image src="/assets/img/arrow-down.svg" alt="Nästa event" /></span>
                    </div>
                    <Slider {...settings} className="mb-md-5 slider-block">
                        {pastCources && pastCources.map((element, index) => (

                            <div className="h-100 slidebox" key={index}>
                                <Image className="w-100" variant="top" src={element?.image} />
                                <div className="position-absolute bottom-0 card-block text-white">
                                    <Card.Body>
                                        <Card.Title className="font-32 text-white fw-light mb-2">{element?.title}</Card.Title>
                                        <Card.Text className="font-22 fw-bold">
                                            {element?.sub_title}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="mt-md-4 mt-2">
                                        <Button className="btn btn-light" onClick={() => showVideo(element?.video_url)}>Se mer</Button>
                                       
                                    </Card.Footer>
                                </div>
                            </div>
                        ))}
                       
                    </Slider>
                </Container>
            </section>
            <section className="pb-120 overflow-hidden" id="about">
              <AboutUs></AboutUs>
            </section>
            
            <Footer/>
        </div>
    )
}

export default Home