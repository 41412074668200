import { useState, useEffect } from 'react';
import { Container, Image, Button, Row, Col, Modal } from 'react-bootstrap';
import Footer from "./Footer";
import Header from "./Header";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { singleActivity } from '../store/api/course.api';
import AboutUs from './AboutUS';

const Detail = () => {
    const [show, setShow] = useState(false);
    const [activity, setActivity] = useState();
    const dispatch = useDispatch();
    const { slug } = useParams();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [deadline, setDeadline] = useState();
    const [displayTimer, setDisplayTimer] = useState(true);


    const getTime = (date) => {
        const time = Date.parse(date) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
        if (time <= 0) {
            setDisplayTimer(false);
        } else {
            setDisplayTimer(true)
        }
    };

    useEffect(() => {
        let data = {
            slug: slug
        }
        dispatch(singleActivity(data)).then((act) => {
            setActivity(act.payload?.data);
            setDeadline(act.payload?.data?.activity_date);
          //  setDeadline('2023-09-20T02:12:00.000Z')
        });
    }, [deadline]);

    useEffect(() => {
        if (deadline) {
            const interval = setInterval(() => getTime(deadline), 1000);
            return () => clearInterval(interval);
        }
    }, [deadline]);
    return (
        <div>
            <Header />

            {displayTimer && deadline &&
                <section className="py-md-5 pt-3 mobile-pb-80" >
                    <Container>
                        <div className="pb-md-5">
                            <span className="d-block text-center font-24 fw-light mb-4">Föreläsningen börjar här om</span>
                            <Row className="gy-4">
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{days}</span>
                                        <span className="font-45 d-block mt-1">Dagar</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{hours}</span>
                                        <span className="font-45 d-block mt-1">Timmar</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{minutes}</span>
                                        <span className="font-45 d-block mt-1">Minuter</span>
                                    </div>
                                </Col>
                                <Col sm={3} xs={6}>
                                    <div className="font-secondary text-center">
                                        <span className="counter-time fw-semibold font-135 d-inline-block mb-4">{seconds}</span>
                                        <span className="font-45 d-block mt-1">Sekunder</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>
            }

            <section className="mobile-pt-20">
                <Container>
                    {!displayTimer && deadline &&
                        <div className="banner-image overflow-hidden rounded-3 mb-38 position-relative">
                            {/* <svg style={{ 'margin-left': 'auto' }} className="blink position-absolute blink-wrap" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                <circle cx="15" cy="15" r="15" fill="#FF0000" />
                            </svg> */} 
                                <iframe className="w-100" width="560" height="100%" src={`https://www.youtube.com/embed/${activity?.live_url}`} title="YouTube video player" frameborder="0" allowfullscreen></iframe>
                        </div>

                    }
                    <div>
                        <div className="d-flex">
                            <Image src={activity?.image} className="object-fit flex-shrink-0  w-66 me-3 d-md-block  rounded-pill" />
                            <div>
                                <h2 className="font-32 fw-light mb-1">{activity?.title}</h2>
                                <span className="font-22 d-block fw-bold">{activity?.sub_title}</span>
                            </div>
                        </div>
                        <div className="block text-black font-16 mt-10">
                            <p dangerouslySetInnerHTML={{ __html: activity?.description }}></p>

                        </div>
                        <div className="d-flex align-items-center gap-3 flex-wrap mt-10">
                            {activity?.book_me_url &&
                                <a variant="dark" href={activity?.book_me_url} target='_blank' className="btn  btn-back  pointer">Boka föreläsare</a>
                            }
                            {activity?.see_course_url &&
                                <a variant="dark" href={activity?.see_course_url} target='_blank' className="btn  btn-back pointer">Se kurs</a>
                            }
                        </div>
                    </div>

                </Container>
            </section>

            <section className="py-120 overflow-hidden" id="about">
                <AboutUs></AboutUs>
            </section>
            {/* <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton className="text-white d-block block text-center">
                    <Modal.Title className="d-block font-32"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-white fw-light block font-22 fw-light">
                
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between text-center d-block">
          <Button variant="dark" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
                

            </Modal> */}

            <Footer />
        </div>
    )
}

export default Detail;