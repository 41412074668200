import axios from 'axios';
import React, { useState, useEffect }  from 'react';

export default function GlobalLoader() {
 // const loading = useAxiosLoader();
 const loading = false;
  return (
    <>
    { (loading) && 
      <div className="loading">
      <div className="row">
        <div className="spin">
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
        </div>
      </div>
    </div>
    }
    </>
  );
}
const useAxiosLoader = () => {
  const [counter, setCounter] = useState(0);
  
  useEffect(() => {
    const inc = mod => setCounter(c => c + mod);
    
    const handleRequest = config => (inc(1), config);
    const handleResponse = response => (inc(-1), response);
    const handleError = error => (inc(-1), Promise.reject(error));
  
    // add request interceptors
    const reqInterceptor = axios.interceptors.request.use(handleRequest, handleError);
    // add response interceptors
    const resInterceptor = axios.interceptors.response.use(handleResponse, handleError);
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(reqInterceptor);
      axios.interceptors.response.eject(resInterceptor);
    };
  }, []);
  
  return counter > 0;
};