import { useState, useEffect } from "react";
import { Button, Form, Modal, Image, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { addActivity,editActivity,singleActivity } from '../store/api/course.api';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const AddActivity= (prop)=> {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState({ title: "", sub_title: "",video_url:"",description:"" });
    const [selectedFile, setSelectedFile] = useState(null);
    const [activity_date, setActivity_date] = useState();
    const [activity_end_date, setActivity_end_date] = useState();
    const dispatch = useDispatch();
    const [textContent, setTextContent] = useState(null);
    const editorConfiguration = {
        toolbar: [  'bold', 'italic' ,'undo', 'redo'],
      };
    const handleClose = () => {
        setShow(false);
        prop.callbackModal();
    }
    useEffect(() => {

        if(prop.editId) {
            let data ={
               slug:prop.editId
            }
            dispatch(singleActivity(data)).then((act) => {
               
               setValue({
                ...value,
                ['title']: act.payload.data.title,
                ['sub_title']:act.payload.data.sub_title,
                ['video_url']:act.payload.data.video_url != "undefined" ?act.payload.data.video_url:'',
                ['live_url']:act.payload.data.live_url != "undefined" ?act.payload.data.live_url:'',
                ['book_me_url']:act.payload.data.book_me_url != "undefined" ?act.payload.data.book_me_url:'',
                ['see_course_url']:act.payload.data.see_course_url != "see_course_url" ?act.payload.data.see_course_url:'',
              
              
              });        
              setActivity_date(new Date(moment(act.payload.data.activity_date).format('YYYY-MM-DD HH:mm')));
              setActivity_end_date(new Date(moment(act.payload.data.activity_end_date).format('YYYY-MM-DD HH:mm')));
              setTextContent(act.payload.data.description);
             }); 
           }
        setShow(prop.show)
    }, []);
    const onChange = (e) => {
        
       
         setValue({
           ...value,
           [e.target.name]: e.target.value,
         });
       };

       const onAdd = () => {
       
        var data = new FormData();
        data.append('title', value.title);
        data.append('sub_title', value.sub_title);
        data.append('video_url', value.video_url);
        data.append('live_url', value.live_url);
        data.append('book_me_url', value.book_me_url);
        data.append('see_course_url', value.see_course_url);
        if(selectedFile) {
        data.append('image', selectedFile, selectedFile.name);
        }
        data.append('description', textContent);
        data.append('activity_date', moment.utc(activity_date).format("YYYY-MM-DD: HH:mm"));
        data.append('activity_end_date', moment.utc(activity_end_date).format("YYYY-MM-DD: HH:mm"));
        
         dispatch(addActivity(data))
         .unwrap()
         .then(() => {
            handleClose();
         
         })
         .catch(() => {
            handleClose();
         });
       
      };
      const onEdit = () => {
      
        var data = new FormData();
        if(selectedFile) {
           data.append('image', selectedFile, selectedFile.name);
        }
        data.append('slug', prop.editId);
        data.append('title', value.title);
        data.append('sub_title', value.sub_title);
        data.append('video_url', value.video_url);
        data.append('live_url', value.live_url);
        data.append('book_me_url', value.book_me_url);
        data.append('see_course_url', value.see_course_url);
        data.append('description', textContent);
        data.append('activity_date', moment.utc(activity_date).format("YYYY-MM-DD: HH:mm"));
        data.append('activity_end_date', moment.utc(activity_end_date).format("YYYY-MM-DD: HH:mm"));
        dispatch(editActivity(data))
        .unwrap()
        .then(() => {
           setShow(false);
           handleClose();
        })
        .catch(() => {
        });
     };   
    return (
        <div className="">
            <Modal show={show} onHide={handleClose} size="md" centered>
                <Modal.Header closeButton className="text-white d-block block text-center">
                    <Modal.Title className="d-block font-32"> {prop.editId? <span>{('Edit Activity')}</span>: <span>{('Morgonföreläsning')}</span>}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-white fw-light block font-22 fw-light">
                <Form className="form-block mx-auto max-387 custum-place ">
                        <Form.Group className="mb-4"  controlId="formBasicTitle">
                            <Form.Control type="text" placeholder="Rubrik" className="no-border no_padding" name="title" autoComplete="off" aria-label="title" value={value.title}  onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicSub">                  
                            <Form.Control type="text" placeholder="Underrubrik" className="no-border no_padding" name="sub_title" autoComplete="off" aria-label="sub_title" value={value.sub_title}  onChange={onChange} />
                        </Form.Group>
                        <Form.Group className="mb-4" controlId="formBasicSub">                  
                            <Form.Control type="text" placeholder="Länk efter event" className="no-border no_padding" name="video_url" autoComplete="off" aria-label="video_url" value={value.video_url}  onChange={onChange} />
                        </Form.Group> 
                        <Form.Group className="mb-4" controlId="formBasicSub">                  
                            <Form.Control type="text" placeholder="Länk till event" className="no-border no_padding" name="live_url" autoComplete="off" aria-label="live_url" value={value.live_url}  onChange={onChange} />
                        </Form.Group> 
                        <Form.Group className="mb-4" controlId="formBasicSub">                  
                            <Form.Control type="text" placeholder="Boka föreläsning" className="no-border no_padding" name="book_me_url" autoComplete="off" aria-label="book_me_url" value={value.book_me_url}  onChange={onChange} />
                        </Form.Group> 
                        <Form.Group className="mb-4" controlId="formBasicSub">                  
                            <Form.Control type="text" placeholder="Köpa kurs" className="no-border no_padding" name="see_course_url" autoComplete="off" aria-label="see_course_url" value={value.see_course_url}  onChange={onChange} />
                        </Form.Group>   
                        {/* <Form.Group className="mb-4 wow fadeInDown" controlId="formGroupAboutUs">
                        <Form.Control as="textarea" rows={8} placeholder="Beskrivning" className="no-border no_padding no_height" name='description' aria-label="description" value={value.description} onChange={onChange}  />
                        </Form.Group> */}
                        <div className='mb-10 wow fadeInDown'>
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={textContent}
                                placeholder="Enter data"
                                onChange={(event, editor) => {
                                    setTextContent(editor.getData());
                                }}

                            />
                        </div>
                        <Form.Group className="mb-10 mt-4" controlId="formBasicDate">
                            <Row>
                                <Col md={6}>
                                <div className='mb-4 wow fadeInDown '>
                            <DatePicker  placeholderText='Datum' className="no-border no_padding form-control" selected={activity_date} onChange={(date) => setActivity_date(date)} 
                            
                            />
                        </div>
                                </Col>
                                <Col md={6}>
                                <div className='mb-4 wow fadeInDown '>
                            <DatePicker  placeholderText='Time' className="no-border no_padding form-control" selected={activity_date} onChange={(date) => setActivity_date(date)} 
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            />
                        </div>
                                </Col>
                            </Row>
                  </Form.Group>
                  <Form.Group className="mb-10 mt-4" controlId="formBasicDate">
                            <Row>
                                <Col md={6}>
                                <div className='mb-4 wow fadeInDown '>
                            <DatePicker  placeholderText='End Datum' className="no-border no_padding form-control" selected={activity_end_date} onChange={(date) => setActivity_end_date(date)} 
                            
                            />
                        </div>
                                </Col>
                                <Col md={6}>
                                <div className='mb-4 wow fadeInDown '>
                            <DatePicker  placeholderText='Time' className="no-border no_padding form-control" selected={activity_end_date} onChange={(date) => setActivity_end_date(date)} 
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                            />
                        </div>
                                </Col>
                            </Row>
                            </Form.Group>
                        <div className='mb-10 wow fadeInDown no-border no_padding mb4'>
                           <input type="file" id="file1" className='visually-hidden' onChange={(e) => setSelectedFile(e.target.files[0])} />
                           <label htmlFor="file1" className='file-uploadblock d-inline-flex align-items-center align-items-center pointer'>
                              <span className='file-icon'><Image src="/assets/img/Video_file_light.png" alt="Select Image" width="48" height="48" /></span>
                              <span>Välj bild</span>
                           </label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="align-items-center justify-content-between text-center d-block">
                

                            {!prop.editId &&
                     <Button variant="dark" type="button" className=" form-button" onClick={onAdd}>
                     Lägg till
                                 </Button>
                        }
                        {prop.editId &&
                    <Button variant="dark" type="button" className=" form-button" onClick={onEdit}>
                    Update
                                </Button>
                        }
                  
                </Modal.Footer>
            </Modal>
        </div>
    )
} 

export default AddActivity