
import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Image, Modal, Row } from "react-bootstrap";



const AboutUs = (prop) => {
  
    return (
        <Container>
        <Row className="gx-5">
            <Col md={6}>
                <div className="mb-md-0 mb-3">
                    <Image src="/assets/img/banner-block.jpg" alt="Om oss" className="rounded-4" />
                </div>
            </Col>
            <Col md={6}>
                <div>
                    <span className="d-block font-32 fw-light mb-1">Om oss</span>
                    
                    <div className="block font-16 ">
                        <p>
                        Host för våra frukostföreläsningar är ingen mindre än Charlotte Dunhoff, mother of Booky.<br></br>
                        <br></br>
<span className="fw-bold">Booky</span> är en plattform där arrangörer matchas med noga utvalda föreläsare, konferencierer och underhållare. Bookys digitala plattform ger Dig möjligheten att kontakta profiler direkt – utan mellanhänder (men, såklart kan Du boka via dess agenter också – ”the old-school-way”!). <br></br> <br></br>

Producent för våra frukostföreläsningar är Marcus Martinson, digital kreatör och medgrundare till Docens.<br></br>
<br></br>
<span className="fw-bold">Docens</span> är en plattform med underhållande och inspirerande utbildningar för företag och individer – utan att göra avkall på kunskap och fakta.  Produktion av hög klass och kurshållare som är experter inom sitt ämne, samtidigt som de är duktiga inspiratörer, skapar de bästa förutsättningarna för inlärning.<br></br><br></br>
Vid frågor och funderingar kontakta: <a className="custome_a" href="mailto:info@frukostforelasning.se">info@frukostforelasning.se</a>
                        </p>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
    )
}
export default AboutUs