import React, { useState, useEffect } from 'react';
import { Container, Image, Table, Button } from "react-bootstrap";

import moment from 'moment'
import { getUserList } from '../store/api/course.api';
import Header from './Header';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const UserList = () => {
    const dispatch = useDispatch();
    const [allCources, setAllCources] = useState();
    const {id } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        refresh();
    }, []);


    const refresh = () => {
        let data = {
            'id': id
          }
        dispatch(getUserList(data)).then((course) => {
            setAllCources(course.payload?.data);
        });
    }
    

const backtolist =() =>{
    navigate('/admin/list');
}
   
      
    return (
        <>
            <div>
                <Header />
                <Container>
                    {/* Starts Table */}
                    <div className='wow fadeInDown'>
                        <div className='d-flex justify-content-between align-items-center  flex-sm-nowrap flex-wrap wow fadeInDown'>
                            <span className="d-block  font-24 fw-light mb-2">Frukostföreläsningar</span>
                            <div className="right-button">
                                <Button variant="dark" className='btn btn-primary  flex-shrink-0 font-18 min-200 w-sm-100 me-sm-3 mb-3' onClick={() => backtolist()}>Back</Button>
                            </div>
                        </div>
                        <Table bordered hover responsive className=''>
                            <thead>
                                <tr>
                                    <th>Nummer</th>
                                    <th>E-post</th>
                                   
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    allCources && allCources.length > 0 && allCources?.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>
                                                {index + 1}
                                            </td>
                                            <td>{item.email}</td>
                                            

                                           
                                            
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </Table>
                        {allCources && allCources.length === 0 &&
                            <p className='text-center'>Tomt</p>
                        }
                    </div>
                    {/* Ends Table */}
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default UserList;